import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Fullwidthimage = (props) => {
  let { pagetitle, fwiurl, textleft, textright } = props;
  fwiurl = fwiurl.substring(2);
  pagetitle = pagetitle.toLowerCase();
  return (
    <>
      <section
        className="Fullwidthimage"
        style={{
          backgroundImage: `url(https://3dctrl.com/webfrontend/${pagetitle}/${fwiurl})`,
        }}
      >
        <Container>
          <Row>
            <Col xs="12" md="6">
              <p className="textitem">{textleft}</p>
            </Col>
            <Col xs="12" md="6">
              <p className="text-right textitem">{textright}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="FullwidthimageMobile">
        <Container>
          <Row>
            <Col>
              <p className="textitem">
                {textleft} {textright}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Fullwidthimage;
