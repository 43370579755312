import React from "react";
import { useFetchPricing } from "../hooks/useFetchPricing";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import HerobannerShort from "../components/HerobannerShort";
import PricingTable from "../components/PricingTable";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "pricing";

const Pricing = () => {
  const { loading, dataPricing } = useFetchPricing(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    const heroimage = {
      pagetitle: dataPricing.data.result.title,
      imgurl: dataPricing.data.result.image,
      herotitle: dataPricing.data.result.content.headline,
      herosubtitle: dataPricing.data.result.content.subheading,
      ctatext: dataPricing.data.result.content.ctatext,
      ctaurl: dataPricing.data.result.content.ctaurl,
    };
    const pricingtable = {
      table: dataPricing.data.result.table,
      tabledescription: dataPricing.data.result.tabledescription,
    };

    return (
      <>
        <HerobannerShort {...heroimage} />
        <PricingTable {...pricingtable} />
      </>
    );
  }
};

export default Pricing;
