import { useState, useEffect, useCallback } from "react";
import axios from "axios";
const auth = {
  username: "montyto@gmail.com",
  password: "S1m30n21",
};

export const useFetchBlogRelatedItems = (url, page) => {
  const [loading, setLoading] = useState(true);
  const [blogRelatedItems, setblogRelatedItems] = useState([]);

  const getblogRelatedItems = useCallback(async () => {
    const res = await axios.post(
      url,
      {
        query: `page('${page}').children.listed`,
        select: {
          title: true,
          content: "page.content",
          url: "page.url",
          pagecontent: "page.content.text.toBlocks",
        },
      },
      { auth }
    );
    const blogRelatedItems = res;
    setblogRelatedItems(blogRelatedItems);
    setLoading(false);
  }, [url, page]);

  useEffect(() => {
    getblogRelatedItems();
  }, [url, page, getblogRelatedItems]);
  return { loading, blogRelatedItems };
};
