import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import parse from "html-react-parser";

//loop trough row items
let rowitems = (item) => {
  //check if item is set
  if (Object.keys(item).length === 0) {
    return "";
  } else {
    return (
      <tbody key={item.id}>
        <tr>
          <td>{item.type}</td>
          <td>{item.basic}</td>
          <td>{item.pro}</td>
          <td>{item.enterprice}</td>
        </tr>
      </tbody>
    );
  }
};

const PricingTable = (props) => {
  let { table, tabledescription } = props;

  return (
    <>
      <section className="PricingTable">
        <Container>
          <Row>
            <Col>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Virtual Studio</th>
                    <th>Virtual Studio Pro*</th>
                    <th>Virtual Studio Enterprise*</th>
                  </tr>
                </thead>
                {table.map(rowitems)}
              </Table>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6" className="tableDescription">
              {parse(tabledescription)}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PricingTable;
