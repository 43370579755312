import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const HerobannerShort = (props) => {
  const { herotitle, herosubtitle, imgurl, ctatext, ctaurl } = props;

  return (
    <>
      <section
        className="heroBanner heroBannerShort"
        style={{ backgroundImage: `url(${imgurl})` }}
      >
        <Container className="heroBannerContainer">
          <Row className="align-items-center">
            <Col xs md="4" className="">
              {herotitle.length > 0 && <h1>{herotitle}</h1>}
              {herosubtitle.length > 0 && (
                <p className="subtitle">{herosubtitle}</p>
              )}
              {ctatext.length > 0 && (
                <a href={`${ctaurl}`} className="btn-primary">
                  {ctatext}
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="heroBannerMobile"
        style={{ backgroundImage: `url(${imgurl})` }}
      >
        <Container className="heroBannerContainer">
          <Row className="align-items-center">
            <Col xs md="4" className="">
              {herotitle.length > 0 && <h1>{herotitle}</h1>}
              {herosubtitle.length > 0 && (
                <p className="subtitle">{herosubtitle}</p>
              )}
              {ctatext.length > 0 && (
                <a href={`${ctaurl}`} className="btn-primary">
                  {ctatext}
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HerobannerShort;
