import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

//loop trough row items
let rowitems = (page, item) => {
  let col = {
    item: item,
    page: page,
  };
  return (
    <Row key={item.columns[0].id}>
      <Rowitem key={item.columns[1].id} col={col} />
    </Row>
  );
};

const Rowitem = (props) => {
  let rowitemdata = props.col.item;
  let rowpage = props.col.page.toLowerCase();

  let itemone = rowitemdata.columns[0];
  let itemtwo = rowitemdata.columns[1];

  //console.log(itemone);

  if (itemone.blocks.length && itemtwo.blocks.length) {
    if (itemone.blocks[0].type === "image") {
      //first block ( 50 % ) has the image
      let blockone = "";
      let blocktwo = "";
      let blockthree = "";
      if (itemone.blocks[0]) {
        blockone = itemone.blocks[0].content.image[0];
      }
      if (itemtwo.blocks[0]) {
        blocktwo = itemtwo.blocks[0].content.text;
      }
      if (itemtwo.blocks[1]) {
        blockthree = itemtwo.blocks[1].content.text;
      }

      return [
        <Col key={itemone.blocks[0].id} xs="12" md="6">
          <img
            src={`https://3dctrl.com/webfrontend/${rowpage}/${blockone}`}
            alt=""
          />
        </Col>,
        <Col
          key={itemtwo.blocks[0].id}
          xs="12"
          md="6"
          className="align-self-center text-center"
        >
          <h2>{parse(blocktwo)}</h2>
          {parse(blockthree)}
          <div className="arrowsLeft"></div>
        </Col>,
      ];
    } else if (itemone.blocks[0].type === "video") {
      //first block ( 50 % ) has video
      let blockone = "";
      let blocktwo = "";
      let blockthree = "";
      if (itemone.blocks[0]) {
        blockone = itemone.blocks[0].content.url;
        //blockone = blockone.split("v=").pop();
        //var iframeurl = `https://www.youtube.com/embed/${blockone}`;
      }
      if (itemtwo.blocks[0]) {
        blocktwo = itemtwo.blocks[0].content.text;
      }
      if (itemtwo.blocks[1]) {
        blockthree = itemtwo.blocks[1].content.text;
      }

      return [
        <Col key={itemone.blocks[0].id} xs="12" md="6">
          {/* <video width="100%" height="348px" autoPlay="autoplay" muted loop>
            <source src={blockone} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video> */}
          <div
            className="videoDiv"
            dangerouslySetInnerHTML={{
              __html: `
              <video
                autoplay
                loop
                muted
                playsinline
                src="${blockone}"
                height="348px"
                width="100%"
              />,
            `,
            }}
          ></div>
        </Col>,
        <Col
          key={itemtwo.blocks[0].id}
          xs="12"
          md="6"
          className="align-self-center text-center"
        >
          <h2>{parse(blocktwo)}</h2>
          {parse(blockthree)}
          <div className="arrowsLeft"></div>
        </Col>,
      ];
    } else if (itemtwo.blocks[0].type === "video") {
      //second block ( 50 % ) has video
      let blockone = "";
      let blocktwo = "";
      let blockthree = "";

      if (itemone.blocks[0]) {
        blocktwo = itemone.blocks[0].content.text;
      }
      if (itemone.blocks[1]) {
        blockthree = itemone.blocks[1].content.text;
      }
      if (itemtwo.blocks[0]) {
        blockone = itemtwo.blocks[0].content.url;
        //blockone = blockone.split("v=").pop();
        //var iframeurl2 = `https://www.youtube.com/embed/${blockone}`;
      }

      return [
        <Col
          key={itemone.blocks[0].id}
          xs="12"
          md="6"
          className="align-self-center text-center"
        >
          <h2>{parse(blocktwo)}</h2>
          {parse(blockthree)}
          <div className="arrowsRight"></div>
        </Col>,
        <Col key={itemtwo.blocks[0].id} xs="12" md="6">
          {/* <video width="100%" height="348px" autoPlay="autoplay" muted loop>
            <source src={blockone} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video> */}
          <div
            className="videoDiv"
            dangerouslySetInnerHTML={{
              __html: `
              <video
                autoplay
                loop
                muted
                playsinline
                src="${blockone}"
                height="348px"
                width="100%"
              />,
            `,
            }}
          ></div>
        </Col>,
      ];
    } else {
      //second block ( 50 % ) has the image
      let blockone = "";
      let blocktwo = "";
      let blockthree = "";

      if (itemone.blocks[0]) {
        blockone = itemone.blocks[0].content.text;
      }
      if (itemone.blocks[1]) {
        blocktwo = itemone.blocks[1].content.text;
      }
      if (itemtwo.blocks[0]) {
        blockthree = itemtwo.blocks[0].content.image[0];
      }

      return [
        <Col
          key={itemone.blocks[0].id}
          xs="12"
          md="6"
          className="align-self-center text-center"
        >
          <h2>{parse(blockone)}</h2>
          {blocktwo.length > 0 && parse(blocktwo)}
          <div className="arrowsRight"></div>
        </Col>,
        <Col key={itemtwo.blocks[0].id} xs="12" md="6">
          <img
            src={`https://3dctrl.com/webfrontend/${rowpage}/${blockthree}`}
            alt=""
          />
        </Col>,
      ];
    }
  } else {
    return "";
  }
};

const Fiftyfiftyrepeater = (props) => {
  const { repeater, pagetitle } = props;
  return (
    <>
      <section className="Fiftyfiftyrepeater">
        <Container className="FiftyfiftyrepeaterContainer">
          {repeater.map(rowitems.bind(pagetitle, pagetitle))}
        </Container>
      </section>
    </>
  );
};

export default Fiftyfiftyrepeater;
