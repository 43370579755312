import React from "react";
import { Container, Carousel } from "react-bootstrap";
import parse from "html-react-parser";

//loop trough row items
let rowitems = (item) => {
  if (item.columns.length > 0) {
    let copy = item.columns[0].blocks[0].content.text;
    let author = item.columns[0].blocks[1].content.text;
    let position = item.columns[0].blocks[2].content.text;

    return (
      <Carousel.Item key={item.id} interval={100000}>
        <div className="copy-container">
          <div className="copy">{parse(copy)}</div>
          <div className="author">{parse(author)}</div>
          <div className="position">{parse(position)}</div>
        </div>
      </Carousel.Item>
    );
  } else {
    return "";
  }
};

const Testimonials = (props) => {
  let testimonials = props.testimonials;

  return (
    <>
      <section className="Testimonials">
        <Container>
          <Carousel indicators={false}>{testimonials.map(rowitems)}</Carousel>
        </Container>
      </section>
    </>
  );
};

export default Testimonials;
