import React from "react";
import "./App.scss";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Features from "./pages/Features";
import Services from "./pages/Services";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Blogitem from "./pages/Blogitem";
import Tutorials from "./pages/Tutorials";
import Faq from "./pages/Faq";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <Navigation />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/features" component={Features} />
        <Route exact path="/services" component={Services} />
        <Route path="/about" component={About} />
        <Route exact path="/resources/blog" component={Blog} />
        <Route
          exact
          path="/resources/blog/:pagetitle"
          children={<Blogitem />}
        ></Route>
        <Route path="/resources/tutorials" component={Tutorials} />
        <Route path="/resources/faq" component={Faq} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/contact" component={Contact} />
        <Route path="*" component={Error} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
