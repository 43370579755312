import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Herobanner = (props) => {
  const { herotitle, herosubtitle, imgurl, ctatext, ctaurl, heroVideo } = props;
  console.log(heroVideo);
  if (heroVideo === undefined) {
    return (
      <>
        <section
          className="heroBanner"
          style={{ backgroundImage: `url(${imgurl})` }}
        >
          <Container className="heroBannerContainer">
            <Row className="align-items-center">
              <Col xs md="4" className="">
                {herotitle.length > 0 && <h1>{herotitle}</h1>}
                {herosubtitle.length > 0 && (
                  <p className="subtitle">{herosubtitle}</p>
                )}
                {ctatext.length > 0 && (
                  <a href={`${ctaurl}`} className="btn-primary">
                    {ctatext}
                  </a>
                )}
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="heroBannerMobile"
          style={{ backgroundImage: `url(${imgurl})` }}
        >
          <Container className="heroBannerContainer">
            <Row className="align-items-center">
              <Col xs md="4" className="">
                {herotitle.length > 0 && <h1>{herotitle}</h1>}
                {herosubtitle.length > 0 && (
                  <p className="subtitle">{herosubtitle}</p>
                )}
                {ctatext.length > 0 && (
                  <a href={`${ctaurl}`} className="btn-primary">
                    {ctatext}
                  </a>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section
          className="heroBanner heroBannerVideo"
          style={{ backgroundImage: `url(${imgurl})` }}
        >
          {/* <video
            width="100%"
            height="348px"
            autoPlay="autoplay"
            playsInline
            muted
            loop
          >
            <source src={heroVideo} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video> */}
          <div
            className="videoDiv"
            dangerouslySetInnerHTML={{
              __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                src="${heroVideo}"
                width="100%"
              />,
            `,
            }}
          ></div>

          <div className="darkOverlay"></div>

          <Container className="heroBannerContainer">
            <Row className="align-items-center">
              <Col xs md="4" className="">
                {herotitle.length > 0 && <h1>{herotitle}</h1>}
                {herosubtitle.length > 0 && (
                  <p className="subtitle">{herosubtitle}</p>
                )}
                {ctatext.length > 0 && (
                  <a href={`${ctaurl}`} className="btn-primary">
                    {ctatext}
                  </a>
                )}
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="heroBannerMobile heroBannerVideoMobile"
          style={{ backgroundImage: `url(${imgurl})` }}
        >
          {/* <video
            width="100%"
            height="348px"
            autoPlay="autoplay"
            muted
            loop
            playsinline
          >
            <source src={heroVideo} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video> */}

          <div
            className="videoDiv"
            dangerouslySetInnerHTML={{
              __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                src="${heroVideo}"
                height="348px"
                width="100%"
              />,
            `,
            }}
          ></div>

          <div className="darkOverlay"></div>

          <Container className="heroBannerContainer">
            <Row className="align-items-center">
              <Col xs md="4" className="">
                {herotitle.length > 0 && <h1>{herotitle}</h1>}
                {herosubtitle.length > 0 && (
                  <p className="subtitle">{herosubtitle}</p>
                )}
                {ctatext.length > 0 && (
                  <a href={`${ctaurl}`} className="btn-primary">
                    {ctatext}
                  </a>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
};

export default Herobanner;
