import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import Fade from "react-reveal/Fade";

//loop trough row items
let rowitems = (item) => {
  return (
    <Row key={item.columns[0].id}>
      <Rowitem key={item.columns[1].id} item={item} />
    </Row>
  );
};

const Rowitem = (props) => {
  let rowitemdata = props.item;

  if (Object.keys(rowitemdata).length === 0) {
    return "";
  } else {
    let itemone = rowitemdata.columns[0];
    let itemtwo = rowitemdata.columns[1];

    let blockone = itemone.blocks[0].content.image[0];
    let blocktwo = itemone.blocks[1].content.text;
    let blockthree = itemone.blocks[2].content.text;

    let blocfour = itemtwo.blocks[0].content.image[0];
    let blocfive = itemtwo.blocks[1].content.text;
    let blocksix = itemtwo.blocks[2].content.text;

    return [
      <Col
        xs="12"
        md="6"
        key={itemone.blocks[0].id}
        className="text-center left-item item"
      >
        <Fade left>
          <img
            className="ico"
            src={`https://3dctrl.com/webfrontend/home/${blockone}`}
            alt=""
          />
          <h3>{blocktwo}</h3>
          {parse(blockthree)}
        </Fade>
      </Col>,
      <Col
        xs="12"
        md="6"
        key={itemtwo.blocks[0].id}
        className="text-center right-item item"
      >
        <Fade right>
          <img
            className="ico"
            src={`https://3dctrl.com/webfrontend/home/${blocfour}`}
            alt=""
          />
          <h3>{blocfive}</h3>
          {parse(blocksix)}
        </Fade>
      </Col>,
    ];
  }
};

const Fiftyfiftyhighlights = (props) => {
  let repeater = props.fiftyfiftyhighlights;

  return (
    <>
      <section className="Fiftyfiftyhighlights">
        <Container>{repeater.map(rowitems)}</Container>
      </section>
    </>
  );
};

export default Fiftyfiftyhighlights;
