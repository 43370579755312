import React from "react";
import { useFetchHome } from "../hooks/useFetchHome";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import Herobanner from "../components/Herobanner";
import Fiftyfiftyrepeater from "../components/Fiftyfiftyrepeater";
import Fiftyfiftyhighlights from "../components/Fiftyfiftyhighlights";
import Fullwidthbuttons from "../components/Fullwidthbuttons";
import FullwidthbuttonsHidden from "../components/FullwidthbuttonsHidden";
import Fullwidthimage from "../components/Fullwidthimage";
import Onethirdrepeater from "../components/Onethirdrepeater";
import Globalbrands from "../components/Globalbrands";
import Testimonials from "../components/Testimonials";
import CookieConsent from "react-cookie-consent";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "home";

const Home = () => {
  const { loading, dataHome } = useFetchHome(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    console.log(dataHome);
    const heroimage = {
      pagetitle: dataHome.data.result.title,
      imgurl: dataHome.data.result.image,
      herotitle: dataHome.data.result.content.headline,
      herosubtitle: dataHome.data.result.content.subheading,
      ctatext: dataHome.data.result.content.ctatext,
      ctaurl: dataHome.data.result.content.ctaurl,
      heroVideo: dataHome.data.result.content.herovideo,
    };
    const fiftyfiftyrepeater = {
      repeater: dataHome.data.result.fiftyfiftyrepeater,
      pagetitle: dataHome.data.result.title,
    };
    const fiftyfiftyhighlights = dataHome.data.result.fiftyfiftyhighlights;
    const fullwidthbuttonsone = {
      fwblefttext: dataHome.data.result.content.fwblefttext,
      fwblefturl: dataHome.data.result.content.fwblefturl,
      fwbrighttext: dataHome.data.result.content.fwbrighttext,
      fwbrighturl: dataHome.data.result.content.fwbrighturl,
    };
    const fwi = {
      pagetitle: dataHome.data.result.title,
      fwiurl: dataHome.data.result.content.fwi,
      textleft: dataHome.data.result.content.fwitextleft,
      textright: dataHome.data.result.content.fwitextright,
    };
    const onethirdrepeater = {
      page: dataHome.data.result.title,
      data: dataHome.data.result.onethirdrepeater,
    };
    const fullwidthbuttonstwo = {
      fwblefttext: dataHome.data.result.content.fwb2lefttext,
      fwblefturl: dataHome.data.result.content.fwb2lefturl,
      fwbrighttext: dataHome.data.result.content.fwb2righttext,
      fwbrighturl: dataHome.data.result.content.fwb2righturl,
    };
    const globalbrands = {
      gbrandstitle: dataHome.data.result.content.gbtitle,
      gbrandsimages: dataHome.data.result.gbrandsimages,
    };
    const testimonials = dataHome.data.result.testimonials;
    const fullwidthbuttonsthree = {
      fwblefttext: dataHome.data.result.content.fwb3lefttext,
      fwblefturl: dataHome.data.result.content.fwb3lefturl,
      fwbrighttext: dataHome.data.result.content.fwb3righttext,
      fwbrighturl: dataHome.data.result.content.fwb3righturl,
    };

    return (
      <>
        <Herobanner {...heroimage} />
        <Fiftyfiftyrepeater {...fiftyfiftyrepeater} />
        <Fiftyfiftyhighlights fiftyfiftyhighlights={fiftyfiftyhighlights} />
        <Fullwidthbuttons {...fullwidthbuttonsone} />
        <Fullwidthimage {...fwi} />
        <Onethirdrepeater {...onethirdrepeater} />
        <Fullwidthbuttons {...fullwidthbuttonstwo} />
        <Globalbrands {...globalbrands} />
        <Testimonials testimonials={testimonials} />
        <FullwidthbuttonsHidden {...fullwidthbuttonsthree} />
        {/* {console.log(data)} */}
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#21cc6e", color: "#ffffff", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          <span style={{ fontSize: "10px" }}>.</span>
        </CookieConsent>
      </>
    );
  }
};

export default Home;
