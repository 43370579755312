import { useState, useEffect, useCallback } from "react";
import axios from "axios";
const auth = {
  username: "montyto@gmail.com",
  password: "S1m30n21",
};

export const useFetchContact = (url, page) => {
  const [loading, setLoading] = useState(true);
  const [dataContact, setdataContact] = useState([]);

  const getdataContact = useCallback(async () => {
    const res = await axios.post(
      url,
      {
        query: `page('${page}')`,
        select: {
          title: true,
          content: "page.content",
          image: "page.content.heroimage.toFile.url",
          copy: "page.content.text.toBlocks",
        },
      },
      { auth }
    );
    const dataContact = res;
    setdataContact(dataContact);
    setLoading(false);
  }, [url, page]);

  useEffect(() => {
    getdataContact();
  }, [url, page, getdataContact]);
  return { loading, dataContact };
};
