import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//loop trough row items
let rowitems = (item) => {
  return (
    <img
      key={item}
      src={`https://3dctrl.com/webfrontend/${item}`}
      alt=""
    />
  );
};

const Globalbrands = (props) => {
  let { gbrandstitle, gbrandsimages } = props;

  return (
    <>
      <section className="Globalbrands">
        <Container>
          <Row>
            <Col>
              <h2>{gbrandstitle}</h2>
            </Col>
          </Row>
          <Row>
            <Col>{gbrandsimages.map(rowitems)}</Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Globalbrands;
