import { React, useState } from "react";
import { useFetchContact } from "../hooks/useFetchContact";
import HerobannerShort from "../components/HerobannerShort";
import { Spinner, Container, Row, Col, Form, Button } from "react-bootstrap";
import { useMailChimp, Status } from "../hooks/useMailChimp";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "contact";

const Contact = () => {
  const [validated, setValidated] = useState(false);
  const { loading, dataContact } = useFetchContact(url, page);

  const [form, setForm] = useState({
    howhelp: "Join Mailing List",
    firstName: "",
    email: "",
    surName: "",
    company: "",
    message: "",
    phone: "",
  });
  const { subscribe, status, error, value } = useMailChimp(
    "https://3dctrl.us19.list-manage.com/subscribe/post?u=f4ba98828e8654acfa2fe608d&amp;id=f5e9c8a447"
  );

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    console.log(form);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    } else {
      event.preventDefault();
      //event.stopPropagation();

      subscribe({
        HOWHELP: form.howhelp.value,
        FNAME: form.firstName.value,
        EMAIL: form.email.value,
        LNAME: form.surName.value,
        CMPNAME: form.company.value,
        MESSAGE: form.message.value,
        PHONE: form.phone.value,
      });
    }
  };

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    const heroimage = {
      pagetitle: "contact",
      imgurl: dataContact.data.result.image,
      herotitle: dataContact.data.result.content.headline,
      herosubtitle: dataContact.data.result.content.subheading,
      ctatext: dataContact.data.result.content.ctatext,
      ctaurl: dataContact.data.result.content.ctaurl,
    };

    // if (status === Status.loading) {
    //   return <div>loading</div>
    // }

    // if (value.includes('Already subscribed')) {
    //   return <div>success already subscribed</div>
    // }

    console.log(value);

    if (status === Status.error) {
      window.scrollTo(0, 0);

      return (
        <>
          <HerobannerShort {...heroimage} />
          <section className="Contact">
            <Container>
              <Row>
                <Col xs="12" md="4" className="mfieldsContainer">
                  <p className="mfields">
                    Sorry, Houston we have a problem, please try again.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      );
    } else if (value) {
      window.scrollTo(0, 0);

      return (
        <>
          <HerobannerShort {...heroimage} />
          <section className="Contact">
            <Container>
              <Row>
                <Col xs="12" md="4" className="mfieldsContainer">
                  <p className="mfields">
                    Thank you for completing the contact form.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      );
    } else {
      window.scrollTo(0, 0);

      return (
        <>
          <HerobannerShort {...heroimage} />
          <section className="Contact">
            <Container>
              <Row>
                <Col xs="12" md="4" className="mfieldsContainer">
                  <p className="mfields">Mandatory fields*</p>
                </Col>
                <Col xs="12" md="8">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="formItem"
                      >
                        <Form.Label>How can we help?*</Form.Label>
                        <Form.Select
                          required
                          aria-label="how can we help"
                          id="howhelp"
                        >
                          <option value="Request Demo">Request Demo</option>
                          <option value="Join Mailing List">
                            Join Mailing List
                          </option>
                          <option value="Request Pricing">
                            Request Pricing
                          </option>
                          <option value="Job Opportunities">
                            Job Opportunities
                          </option>
                          <option value="More Information">
                            More Information
                          </option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please select one of the options
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Form.Label>Company</Form.Label>
                        <Form.Control type="text" id="company" />
                        <Form.Control.Feedback type="invalid">
                          Company name not valid field
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom03"
                        className="formItem"
                      >
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control required type="text" id="firstName" />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid name.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom04"
                      >
                        <Form.Label>Surname*</Form.Label>
                        <Form.Control required type="text" id="surName" />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid name.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom05"
                        className="formItem"
                      >
                        <Form.Label>Email*</Form.Label>
                        <Form.Control required type="email" id="email" />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid email address.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom06"
                      >
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="tel" id="phone" />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid phone number.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group
                        controlId="validationCustom07"
                        className="formItem"
                      >
                        <Form.Label>Message*</Form.Label>
                        <Form.Control
                          required
                          as="textarea"
                          id="message"
                          rows={10}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid message.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Row>
                    <Row className="mb-3 mfieldsContainer-mobile">
                      <p className="mfields">Mandatory fields*</p>
                    </Row>

                    <Button type="submit">Send</Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      );
    }
  }
};

export default Contact;
