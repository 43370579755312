import { React } from "react";
import { useFetchTutorials } from "../hooks/useFetchTutorials";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import TutorialSingle from "../components/TutorialSingle";
//import { Link } from "react-router-dom";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "Tutorials";

let items = (item) => {
  let img = item.content.featuredimage;
  img = img.substring(2);
  let postTitle = item.content.title;
  let postTitleClean = postTitle.replaceAll(" ", "-");
  let imgurl = `https://3dctrl.com/webfrontend/Tutorials/${postTitleClean}/${img}`;
  let postExceprt = item.content.text;
  postExceprt.slice(0, 50);
  let postDate = item.content.date;
  let postVideo = item.content.videourl;

  let props = {
    image: imgurl,
    postExceprt: postExceprt,
    postTitle: postTitle,
    postDate: postDate,
    postVideo: postVideo,
    postTitleClean: postTitleClean,
  };

  return <TutorialSingle {...props} key={postTitleClean} />;
};

const Tutorials = () => {
  const { loading, dataTutorials } = useFetchTutorials(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    let pages = dataTutorials.data.result.data;

    return (
      <section className="TutorialsPage">
        <Container>
          <Row>{pages.map(items)}</Row>
        </Container>
      </section>
    );
  }
};

export default Tutorials;
