import React from "react";
import { useFetchAbout } from "../hooks/useFetchAbout";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import HerobannerShort from "../components/HerobannerShort";
import ContentAbout from "../components/ContentAbout";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "about-us";

const About = () => {
  const { loading, dataAbout } = useFetchAbout(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    const heroimage = {
      pagetitle: "about",
      imgurl: dataAbout.data.result.image,
      herotitle: dataAbout.data.result.content.headline,
      herosubtitle: dataAbout.data.result.content.subheading,
      ctatext: dataAbout.data.result.content.ctatext,
      ctaurl: dataAbout.data.result.content.ctaurl,
    };
    const content = {
      content: dataAbout.data.result.copy,
      pagetitle: dataAbout.data.result.title,
    };

    return (
      <>
        <HerobannerShort {...heroimage} />
        <ContentAbout {...content} />
      </>
    );
  }
};

export default About;
