import { useState, useEffect, useCallback } from "react";
import axios from "axios";
const auth = {
  username: "montyto@gmail.com",
  password: "S1m30n21",
};

export const useFetchHome = (url, page) => {
  const [loading, setLoading] = useState(true);
  const [dataHome, setdataHome] = useState([]);

  const getdataHome = useCallback(async () => {
    const res = await axios.post(
      url,
      {
        query: `page('${page}')`,
        select: {
          title: true,
          content: "page.content",
          image: "page.content.heroimage.toFile.url",
          fiftyfiftyrepeater: "page.content.ffr.toLayouts",
          fiftyfiftyhighlights: "page.content.ffhr.toLayouts",
          onethirdrepeater: "page.content.othr.toLayouts",
          gbrandsimages: "page.content.gbimages.toFiles",
          testimonials: "page.content.testimonial.toLayouts",
        },
      },
      { auth }
    );
    const dataHome = res;
    setdataHome(dataHome);
    setLoading(false);
  }, [url, page]);

  useEffect(() => {
    getdataHome();
  }, [url, page, getdataHome]);
  return { loading, dataHome };
};
