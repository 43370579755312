import { useState, useEffect, useCallback } from "react";
import axios from "axios";
const auth = {
  username: "montyto@gmail.com",
  password: "S1m30n21",
};

export const useFetchFeatures = (url, page) => {
  const [loading, setLoading] = useState(true);
  const [dataFeatures, setdataFeatures] = useState([]);

  const getdataFeatures = useCallback(async () => {
    const res = await axios.post(
      url,
      {
        query: `page('${page}')`,
        select: {
          title: true,
          content: "page.content",
          image: "page.content.heroimage.toFile.url",
          fiftyfiftyrepeater: "page.content.ffr.toLayouts",
        },
      },
      { auth }
    );
    const dataFeatures = res;
    setdataFeatures(dataFeatures);
    setLoading(false);
  }, [url, page]);

  useEffect(() => {
    getdataFeatures();
  }, [url, page, getdataFeatures]);
  return { loading, dataFeatures };
};
