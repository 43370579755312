import { React, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import parse from "html-react-parser";

const TutorialSingle = (props) => {
  const { image, postExceprt, postTitle, postDate, postVideo, postTitleClean } =
    props;
  const [smShow, setSmShow] = useState(false);

  let videourl = postVideo;
  videourl = videourl.split("v=").pop();
  let iframeurl = `https://www.youtube.com/embed/${videourl}`;

  //check if tutorial has video, and if not - disable click
  if (videourl.includes('youtube')) {

    videourl = videourl.split("v=").pop();
    iframeurl = `https://www.youtube.com/embed/${videourl}`;

    return (
      <Col
        key={postTitleClean}
        onClick={() => setSmShow(true)}
        xs="12"
        md="4"
        className="postCard"
      >
        <img src={image} alt="" />
        <div className="postDate">{postDate}</div>
        <h3 className="postTitle">{postTitle}</h3>
        {/* <div className="excerpt">{parse(postExceprt)}</div> */}
        <div onClick={(e) => e.stopPropagation()}>
          <Modal
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby={postTitleClean}
          >
            <Modal.Header closeButton>
              <Modal.Title id={postTitleClean}></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                width="100%"
                height="100%"
                src={iframeurl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Modal.Body>
          </Modal>
        </div>
      </Col>
    );

  } else if (videourl.includes('vimeo')) {

    iframeurl = videourl;

    return (
      <Col
        key={postTitleClean}
        onClick={() => setSmShow(true)}
        xs="12"
        md="4"
        className="postCard"
      >
        <img src={image} alt="" />
        <div className="postDate">{postDate}</div>
        <h3 className="postTitle">{postTitle}</h3>
        <div className="excerpt">{parse(postExceprt)}</div>
        <div onClick={(e) => e.stopPropagation()}>
          <Modal
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby={postTitleClean}
          >
            <Modal.Header closeButton>
              <Modal.Title id={postTitleClean}></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                width="100%"
                height="100%"
                src={iframeurl}
                title="Vimeo video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Modal.Body>
          </Modal>
        </div>
      </Col>
    );

  } else {

    return (
      <Col
        key={postTitleClean}
        //onClick={() => setSmShow(true)}
        xs="12"
        md="4"
        className="postCard"
      >
        <img src={image} alt="" />
        <div className="postDate">{postDate}</div>
        <h3 className="postTitle">{postTitle}</h3>
        <div className="excerpt">{parse(postExceprt)}</div>
        <div onClick={(e) => e.stopPropagation()}>
          <Modal
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby={postTitleClean}
          >
            <Modal.Header closeButton>
              <Modal.Title id={postTitleClean}></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                width="100%"
                height="100%"
                src={iframeurl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Modal.Body>
          </Modal>
        </div>
      </Col>
    );

  }
};

export default TutorialSingle;
