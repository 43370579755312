import React from "react";
import { useFetchServices } from "../hooks/useFetchServices";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import Herobanner from "../components/Herobanner";
import Fiftyfiftyrepeater from "../components/Fiftyfiftyrepeater";
import Fullwidthbuttons from "../components/Fullwidthbuttons";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "services";

const Services = () => {
  const { loading, dataServices } = useFetchServices(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    const heroimage = {
      pagetitle: dataServices.data.result.title,
      imgurl: dataServices.data.result.image,
      herotitle: dataServices.data.result.content.headline,
      herosubtitle: dataServices.data.result.content.subheading,
      ctatext: dataServices.data.result.content.ctatext,
      ctaurl: dataServices.data.result.content.ctaurl,
    };
    const fiftyfiftyrepeater = {
      repeater: dataServices.data.result.fiftyfiftyrepeater,
      pagetitle: dataServices.data.result.title,
    };
    const fullwidthbuttonsone = {
      fwblefttext: dataServices.data.result.content.fwblefttext,
      fwblefturl: dataServices.data.result.content.fwblefturl,
      fwbrighttext: dataServices.data.result.content.fwbrighttext,
      fwbrighturl: dataServices.data.result.content.fwbrighturl,
    };

    return (
      <>
        <Herobanner {...heroimage} />
        <Fiftyfiftyrepeater {...fiftyfiftyrepeater} />
        <Fullwidthbuttons {...fullwidthbuttonsone} />

        {/* {console.log(data)} */}
      </>
    );
  }
};

export default Services;
