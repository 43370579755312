import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

//loop trough row items
let rowitems = (page, item) => {
  //sanitize page for "About us page"
  if (page === "About Us") {
    page = "about-us";
  }
  //conditional rendering
  if (item.type === "heading") {
    return (
      <Row key={item.id}>
        <h3>{item.content.text}</h3>
      </Row>
    );
  } else if (item.type === "text" || item.type === "list") {
    return <Row key={item.id}>{parse(item.content.text)}</Row>;
  } else if (item.type === "image") {
    return (
      <Row key={item.id}>
        <img
          src={`https://3dctrl.com/webfrontend/${page}/${item.content.image[0]}`}
          alt=""
        />
      </Row>
    );
  } else if (item.type === "video") {
    //sanitize video url and pop excess
    let videourl = item.content.url;
    videourl = videourl.split("=").pop();
    let iframeurl = `https://www.youtube.com/embed/${videourl}`;

    return (
      <Row key={item.id}>
        <iframe
          width="100%"
          height="348"
          src={iframeurl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Row>
    );
  }
};

const ContentAbout = (props) => {
  let { content, pagetitle } = props;

  return (
    <>
      <section className="ContentAbout">
        <Container>
          <Row>
            <Col xs="12" md="4"></Col>
            <Col xs="12" md="6">
              {content.map(rowitems.bind(pagetitle, pagetitle))}
            </Col>
            <Col xs="12" md="4"></Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContentAbout;
