import { useState, useEffect, useCallback } from "react";
import axios from "axios";
const auth = {
  username: "montyto@gmail.com",
  password: "S1m30n21",
};

export const useFetchPricing = (url, page) => {
  const [loading, setLoading] = useState(true);
  const [dataPricing, setdataPricing] = useState([]);

  const getdataPricing = useCallback(async () => {
    const res = await axios.post(
      url,
      {
        query: `page('${page}')`,
        select: {
          title: true,
          content: "page.content",
          image: "page.content.heroimage.toFile.url",
          table: "page.content.pricingtable.toStructure",
          tabledescription: "page.content.pricingdescription.kirbytext",
        },
      },
      { auth }
    );
    const dataPricing = res;
    setdataPricing(dataPricing);
    setLoading(false);
  }, [url, page]);

  useEffect(() => {
    getdataPricing();
  }, [url, page, getdataPricing]);
  return { loading, dataPricing };
};
