import React from "react";
import { useFetchFeatures } from "../hooks/useFetchFeatures";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import Herobanner from "../components/Herobanner";
import Fiftyfiftyrepeater from "../components/Fiftyfiftyrepeater";
import Fullwidthbuttons from "../components/Fullwidthbuttons";

const url = "https://3dctrl.com/webfrontend/api/query/";
const page = "features";

const Features = () => {
  const { loading, dataFeatures } = useFetchFeatures(url, page);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col className="mt-5 mb-5 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  } else {
    const heroimage = {
      pagetitle: dataFeatures.data.result.title,
      imgurl: dataFeatures.data.result.image,
      herotitle: dataFeatures.data.result.content.headline,
      herosubtitle: dataFeatures.data.result.content.subheading,
      ctatext: dataFeatures.data.result.content.ctatext,
      ctaurl: dataFeatures.data.result.content.ctaurl,
    };
    const fiftyfiftyrepeater = {
      repeater: dataFeatures.data.result.fiftyfiftyrepeater,
      pagetitle: dataFeatures.data.result.title,
    };
    const fullwidthbuttonsone = {
      fwblefttext: dataFeatures.data.result.content.fwblefttext,
      fwblefturl: dataFeatures.data.result.content.fwblefturl,
      fwbrighttext: dataFeatures.data.result.content.fwbrighttext,
      fwbrighturl: dataFeatures.data.result.content.fwbrighturl,
    };

    return (
      <>
        <Herobanner {...heroimage} />
        <Fiftyfiftyrepeater {...fiftyfiftyrepeater} />
        <Fullwidthbuttons {...fullwidthbuttonsone} />

        {/* {console.log(data)} */}
      </>
    );
  }
};

export default Features;
