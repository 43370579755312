import React from "react";
import { Link, Switch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <footer>
        <Switch>
          <Container>
            <Row>
              <Col xs="12" md="3">
                <p className="footer-heading">Developed by</p>
                <a
                  href="/web/about"
                  className="icopages"
                  target="_self"
                  rel="noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/footerlogo.svg"}
                    alt="logo"
                    className="logo"
                  ></img>
                </a>
                <p className="footer-heading-small">
                  &copy; Copyright Artificial Artists Ltd 2022. <br />
                  All Rights Reserved.
                </p>
              </Col>
              <Col xs="12" md="3" className="pages">
                <p className="footer-heading">Find us</p>
                <a
                  href="https://www.instagram.com/3dctrl/"
                  className="icopages"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="instagramico"></div> Instagram
                </a>
                <a
                  href="https://twitter.com/3dctrl"
                  className="icopages"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="twitterico"></div> Twitter
                </a>
                <a
                  href="https://www.linkedin.com/company/3dctrl/"
                  className="icopages"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="linkedinico"></div> LinkedIn
                </a>
                <a
                  href="https://vimeo.com/3dctrl"
                  className="icopages"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="vimeoico"></div> Vimeo
                </a>
              </Col>
              <Col xs="12" md="3" className="pages">
                <Link to="/features">Features</Link>
                <Link to="/services">Services</Link>
                <Link to="/about">About Us</Link>
                {/*<Link to="/pricing">Pricing</Link>*/}
                <Link to="/contact">Contact</Link>
              </Col>
              <Col xs="12" md="3" className="pages">
                <p className="footer-heading">Resources</p>
                <Link to="/resources/blog">- Blog + Case Studies</Link>
                <Link to="/resources/tutorials">- Tutorials</Link>
                <Link to="/resources/faq">- FAQ</Link>
              </Col>
            </Row>
          </Container>
        </Switch>
      </footer>
    </>
  );
};

export default Footer;
