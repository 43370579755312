import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const FullwidthbuttonsHidden = (props) => {
  const { fwblefttext, fwblefturl, fwbrighttext, fwbrighturl } = props;
  return (
    <>
      <section className="Fullwidthbuttons hideMobile">
        <Container>
          <Row>
            <Col xs="12" md="6" className="leftCol">
              <a className="btn-primary" href={`${fwblefturl}`}>
                {fwblefttext}
              </a>
            </Col>
            <Col xs="12" md="6" className="rightCol">
              <a className="btn-secondary" href={`${fwbrighturl}`}>
                {fwbrighttext}
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FullwidthbuttonsHidden;
