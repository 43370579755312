import React from "react";
import { Container, Row } from "react-bootstrap";
import parse from "html-react-parser";
import Fade from "react-reveal/Fade";

//loop trough row items
let rowitems = (page, item) => {
  let col = {
    item: item,
    page: page,
  };
  return (
    <Row key={item.columns[0].id}>
      <Rowitem key={item.columns[1].id} col={col} />
    </Row>
  );
};

//build single col blocks
const Rowitem = (props) => {
  let rowitemdata = props.col.item;

  if (Object.keys(rowitemdata).length === 0) {
    return "";
  } else {
    let rowpage = props.col.page.toLowerCase();

    let itemOne = rowitemdata.columns[0];
    let itemTwo = rowitemdata.columns[1];
    let itemThree = rowitemdata.columns[2];

    if (itemOne && itemTwo && itemThree) {
      let itemAllOutput = `<div className="col-xs-12 col-md-4"><img src="https://3dctrl.com/webfrontend/${rowpage}/${itemOne.blocks[0].content.image}"/><h2>${itemOne.blocks[1].content.text}</h2><div class="text">${itemOne.blocks[2].content.text}</div></div>  <div className="col-xs-12 col-md-4"><img src="https://3dctrl.com/webfrontend/${rowpage}/${itemTwo.blocks[0].content.image}"/><h2>${itemTwo.blocks[1].content.text}</h2><div class="text">${itemTwo.blocks[2].content.text}</div></div>  <div className="col-xs-12 col-md-4"><img src="https://3dctrl.com/webfrontend/${rowpage}/${itemThree.blocks[0].content.image}"/><h2>${itemThree.blocks[1].content.text}</h2><div class="text">${itemThree.blocks[2].content.text}</div></div>`;

      return parse(itemAllOutput);
    } else if (itemOne && itemTwo) {
      let itemOneAndTwoOutput = `<div className="col-xs-12 col-md-4"><img src="https://3dctrl.com/webfrontend/${rowpage}/${itemOne.blocks[0].content.image}"/><h2>${itemOne.blocks[1].content.text}</h2><div class="text">${itemOne.blocks[2].content.text}</div></div>  <div className="col-xs-12 col-md-4"><img src="https://3dctrl.com/webfrontend/${rowpage}/${itemTwo.blocks[0].content.image}"/><h2>${itemTwo.blocks[1].content.text}</h2><div class="text">${itemTwo.blocks[2].content.text}</div></div>`;

      return parse(itemOneAndTwoOutput);
    } else if (itemOne) {
      let itemOneOutput = `<div className="col-xs-12 col-md-4"><img src="https://3dctrl.com/webfrontend/${rowpage}/${itemOne.blocks[0].content.image}"/><h2>${itemOne.blocks[1].content.text}</h2><div class="text">${itemOne.blocks[2].content.text}</div></div>`;

      return parse(itemOneOutput);
    }
  }
};

const Onethirdrepeater = (props) => {
  let { data, page } = props;

  return (
    <>
      <section className="Onethirdrepeater">
        <Fade bottom>
          <Container>{data.map(rowitems.bind(page, page))}</Container>
        </Fade>
      </section>
    </>
  );
};

export default Onethirdrepeater;
